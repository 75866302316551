import SensorTypeHelper from './TypeHelper'
import { ROOT_ZONE, CLIMATE_ZONE } from './zones'
import sensorVersions from './versions'
import temperatureUnits from './temperatureUnits'

const zones = [
  CLIMATE_ZONE,
  ROOT_ZONE,
]

export {
  CLIMATE_ZONE,
  ROOT_ZONE,
  SensorTypeHelper,
  sensorVersions,
  temperatureUnits,
  zones,
}
