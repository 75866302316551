import { ROOT_ZONE } from '../zones'
import TEMP from './TEMP'
import { Translator } from '~gro-plugins'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'ROOT_TEMP'

export default {
  axis: TEMP.axis,
  axisLimits: TEMP.axisLimits,
  colors: {
    primary: '#a0d900',
    secondary: '#a0d900',
  },
  convertValues: TEMP.convertValues,
  convertValue: TEMP.convertValue,
  convertValueForTemperatureUnit: TEMP.convertValueForTemperatureUnit,
  icon: TEMP.icon,
  formatValue: TEMP.formatValue,
  $t (key) {
    if (key === 'label') return Translator.translate('sensor.types.ROOT_TEMP.label')
    return TEMP.$t(key)
  },
  async $tAsync (key) {
    if (key === 'label') return Translator.translate('sensor.types.ROOT_TEMP.label')
    return TEMP.$tAsync(key)
  },
  svg: asset('/images/icons/RZTEMP_Color_12x12.svg'),
  zone: ROOT_ZONE,
  zoneListPosition: 2,
  order: 3,
}
