import { Translator } from '~gro-plugins'
import { reactive } from 'vue'
import { CLIMATE_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { CELSIUS, FAHRENHEIT } from '../temperatureUnits'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'TEMP'

export default {
  axis: async (chartType) => {
    const isFahrenheit = await unitIsFahrenheit()
    const setting = await Settings.get('axis.TEMP', { autoScale: true })
    return {
      id,
      opposite: false,
      softMax: setting.autoScale ? (isFahrenheit ? 90 : 35) : null,
      softMin: setting.autoScale ? (isFahrenheit ? 60 : 15) : null,
      ceiling: setting.autoScale ? isFahrenheit ? 110 : 45 : null,
      minRange: isFahrenheit ? 20 : 10,
      min: setting.autoScale ? null : setting.min,
      max: setting.autoScale ? null : setting.max,
      floor: setting.autoScale ? 0 : null,
      alignTicks: false,
      endOnTick: false,
      gridLineWidth: 0,
      tickAmount: 5,
      allowDecimals: false,
      title: {
        enabled: false,
      },
      labels: chartType === 'conditions' && chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              fontSize: '10px',
              color: '#26cc21',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: async () => {
    const isFahrenheit = await unitIsFahrenheit()
    return {
      min: isFahrenheit ? 32 : 0,
      max: isFahrenheit ? 110 : 45,
      stepSize: isFahrenheit ? 1 : 0.5,
    }
  },
  async convertValues (rawValues) {
    if (!await unitIsFahrenheit()) return rawValues
    return rawValues.map(rawValue => {
      if (rawValue) return convertToFahrenheit(rawValue)
      return null
    })
  },
  async convertValue (rawValue) {
    const useFahrenheit = await unitIsFahrenheit()

    return this.convertValueForTemperatureUnit(rawValue, useFahrenheit)
  },
  convertValueForTemperatureUnit (rawValue, useFahrenheit) {
    if (!useFahrenheit) {
      return rawValue
    }

    if (typeof rawValue !== 'number') {
      return null
    }

    return convertToFahrenheit(rawValue)
  },
  colors: {
    primary: '#26cc21',
    secondary: '#26cc21',
  },
  icon: 'thermometer',
  svg: asset('/images/icons/CLTEMP_Color_12x12.svg'),
  $t (key) {
    if (!Object.keys(asyncTranslations).includes(key)) return Translator.translate(`sensor.types.TEMP.${key}`)
    updateTranslations()
    return asyncTranslations[key]
  },
  async $tAsync (key) {
    if (!Object.keys(asyncTranslations).includes(key)) return Translator.translate(`sensor.types.TEMP.${key}`)
    await updateTranslations()
    return asyncTranslations[key]
  },
  zone: CLIMATE_ZONE,
  zoneListPosition: 0,
  order: 4,
}

const asyncTranslations = reactive({
  unit: '',
})

async function updateTranslations () {
  if (await unitIsFahrenheit()) {
    asyncTranslations.unit = Translator.translate('sensor.types.TEMP.unit_farh')
  } else {
    asyncTranslations.unit = Translator.translate('sensor.types.TEMP.unit')
  }
}

async function unitIsFahrenheit () {
  return await Settings.get('temperatureUnit', CELSIUS) === FAHRENHEIT
}

function convertToFahrenheit (rawValue) {
  return (rawValue * 1.8) + 32
}
