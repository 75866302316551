// import LightValue from '../components/LightValue'
import { CLIMATE_ZONE } from '../zones'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'LIGHT'
export const THRESHOLD_VALUE = 50
export default {
  colors: {
    primary: '#ffb600',
    secondary: '#ffb600',
  },
  icon: 'bulb',
  svg: asset('/images/icons/LIGHT_Color_12x12.svg'),
  zone: CLIMATE_ZONE,
  formatValue (rawValue) {
    return Math.round(rawValue)
  },
  // valueComponent: LightValue,
}
