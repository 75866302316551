import { computed, reactive } from 'vue'
import { Translator } from '~gro-plugins'
import { Singleton } from '~gro-helpers'
import { Settings } from '~gro-modules/Auth'
import { CELSIUS, FAHRENHEIT } from '~gro-modules/Sensor/temperatureUnits'

import BATTERY_PERCENTAGE from './types/BATTERY_PERCENTAGE'
import CO2 from './types/CO2'
import EC from './types/EC'
import EC_MODEL_HIGH from './types/EC_MODEL_HIGH'
import EC_MODEL_LOW from './types/EC_MODEL_LOW'
import LIGHT from './types/LIGHT'
import RH from './types/RH'
import ROOT_TEMP from './types/ROOT_TEMP'
import SIGNAL_STRENGTH from './types/SIGNAL_STRENGTH'
import TEMP from './types/TEMP'
import VPD from './types/VPD'
import WC from './types/WC'
import WC_MODEL_HIGH from './types/WC_MODEL_HIGH'
import WC_MODEL_LOW from './types/WC_MODEL_LOW'

import UNKNOWN from './types/UNKNOWN'

const SUPPORTED_TYPES = {
  BATTERY_PERCENTAGE,
  CO2,
  EC,
  EC_MODEL_HIGH,
  EC_MODEL_LOW,
  LIGHT,
  RH,
  ROOT_TEMP,
  SIGNAL_STRENGTH,
  TEMP,
  VPD,
  WC,
  WC_MODEL_HIGH,
  WC_MODEL_LOW,
}

const COLOR_NAME_CONVENTION = { primary: '', secondary: '-secondary' }
const types = { UNKNOWN, ...SUPPORTED_TYPES }

class TypeHelper extends Singleton {
  constructor () {
    super(TypeHelper, instance => {
      instance._data = reactive({})
      instance.typeSettings = {}
      instance.typesByZone = {}
      instance.extendedTypesByZone = {}
      instance.extendedTypes = []
      instance.renderableTypes = []
      instance.chartRenderableTypes = []
      Object.keys(types).forEach(type => {
        instance.typeSettings[type] = types[type]
        instance._data[type] = {}
        const typeSettings = types[type]
        if (typeSettings.axis && !typeSettings.extended) {
          instance.chartRenderableTypes[typeSettings.order] = type
        }
        if (typeSettings.zone) {
          if (typeSettings.extended) {
            instance.extendedTypes.push(type)
            if (!instance.extendedTypesByZone[typeSettings.zone]) {
              instance.extendedTypesByZone[typeSettings.zone] = []
            }
            instance.extendedTypesByZone[typeSettings.zone].push(type)
          } else {
            instance.renderableTypes.push(type)
            if (typeSettings.zoneListPosition !== undefined) {
              if (!instance.typesByZone[typeSettings.zone]) {
                instance.typesByZone[typeSettings.zone] = []
              }
              instance.typesByZone[typeSettings.zone][typeSettings.zoneListPosition] = type
            }
          }
        }
      })
      instance.chartRenderableTypes = Object.values(instance.chartRenderableTypes)
    })
  }

  getRenderableTypes (extended = false) {
    if (!extended) return this.renderableTypes
    return [
      ...this.renderableTypes,
      ...this.extendedTypes,
    ]
  }

  getRenderableTypesForZone (zone, extended = false) {
    if (!extended) return this.typesByZone[zone]
    return [
      ...this.typesByZone[zone],
      ...this.extendedTypesByZone[zone],
    ]
  }

  filterRenderableTypes (types, extended = false) {
    let eligibleTypes
    if (extended) {
      eligibleTypes = [
        ...this.renderableTypes,
        ...this.extendedTypes,
      ]
    } else {
      eligibleTypes = this.renderableTypes
    }
    return eligibleTypes.filter(type => types.includes(type))
  }

  filterChartRenderableTypes (types) {
    return this.chartRenderableTypes.filter(type => types.includes(type))
  }

  filterTypesForZone (types = [], zone, extended = false) {
    const eligibleTypes = this.getRenderableTypesForZone(zone, extended)
    if (!eligibleTypes || !eligibleTypes.length) return []
    return eligibleTypes.filter(type => types.includes(type))
  }

  getZoneForType (type) {
    if (!this.isSupported(type)) return null
    return this.typeSettings[type].zone
  }

  async getValueAsync (type, rawValue) {
    type = this._getSupportedType(type)
    if (!this.typeSettings[type] || !this.typeSettings[type].convertValue) return rawValue
    return this.typeSettings[type].convertValue(rawValue)
  }

  async getValuesAsync (type, rawValues) {
    type = this._getSupportedType(type)
    if (!this.typeSettings[type] || !this.typeSettings[type].convertValues) return rawValues
    return this.typeSettings[type].convertValues(rawValues)
  }

  async formatValuesAsync (type, rawValues) {
    const useFahrenheit = await Settings.get('temperatureUnit', CELSIUS) === FAHRENHEIT

    const formatMethod = this.typeSettings[type].formatValue
    const convertForTempUnitMethod = this.typeSettings[type].convertValueForTemperatureUnit

    return Promise.all(rawValues.map(async rawValue => {
      if (formatMethod) {
        return formatMethod(rawValue)
      }

      let value
      if (convertForTempUnitMethod) {
        value = convertForTempUnitMethod(rawValue, useFahrenheit)
      } else {
        value = await this.getValueAsync(type, rawValue)
      }

      const rounded = Math.round(value * 10) / 10

      return rounded.toFixed(1)
    }))
  }

  async formatValueAsync (type, rawValue) {
    if (typeof rawValue !== 'number') return '-'
    type = this._getSupportedType(type)

    if (this.typeSettings[type] && this.typeSettings[type].formatValue) {
      return this.typeSettings[type].formatValue(rawValue)
    }

    const rounded = Math.round(await this.getValueAsync(type, rawValue) * 10) / 10
    return rounded.toFixed(1)
  }

  getSetting (type, key, fallback = null) {
    if (!this._data[type][key]) {
      this._data[type][key] = fallback
      this._fillSettingValue(type, key)
    }
    return this._data[type][key]
  }

  async getSettingAsync (type, key, ...args) {
    return this._getSettingValue(type, key, args)
  }

  getColor (type, level = 'primary') {
    const colors = this.getSetting(type, 'colors', {})
    return computed(() => {
      return colors[level] || '#555'
    })
  }

  async getColorAsync (type, level = 'primary') {
    const colors = await this.getSettingAsync(type, 'colors', {})
    return colors[level] || '#555'
  }

  getColorName (type, level = 'primary') {
    return `${this.getClassname(type)}${COLOR_NAME_CONVENTION[level]}`
  }

  getClassname (type) {
    return type.replace('_', '-').toLowerCase()
  }

  $t (typeName, key) {
    const type = this._getSupportedType(typeName)
    if (this.typeSettings[type] && this.typeSettings[type].$t) return this.typeSettings[type].$t(key)
    return Translator.translate(`sensor.types.${typeName}.${key}`)
  }

  async $tAsync (typeName, key) {
    const type = this._getSupportedType(typeName)
    if (this.typeSettings[type] && this.typeSettings[type].$t) return this.typeSettings[type].$tAsync(key)
    return Translator.translate(`sensor.types.${typeName}.${key}`)
  }

  _getSupportedType (type) {
    if (!this.isSupported(type)) return UNKNOWN
    return type
  }

  isSupported (type) {
    return !!SUPPORTED_TYPES[type]
  }

  async _fillSettingValue (type, key) {
    const value = await this._getSettingValue(type, key)
    if (value) this._data[type][key] = value
  }

  async _getSettingValue (type, key, args = []) {
    type = this._getSupportedType(type)
    if (this.typeSettings[type] && this.typeSettings[type][key]) {
      if (typeof this.typeSettings[type][key] !== 'function') {
        return this.typeSettings[type][key]
      } else {
        return this.typeSettings[type][key](...args)
      }
    }
    return null
  }
}

export default new TypeHelper()
