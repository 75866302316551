import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async startScan (type) {
    await apolloClient.mutate({
      mutation: gql`mutation($type: GatewayType!) {
        gatewayScan(type:$type)
      }`,
      variables: {
        type,
      },
    })
  },

  async addManually (gateway) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation($ipAddress: String!, $type: GatewayType!) {
        gatewayAddManually(
          ipAddress: $ipAddress,
          type:$type
        )
      }`,
      variables: {
        ...gateway,
      },
    })

    return !result.errors
  },

  async installCandidate (hardwareAddress, type) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($type: GatewayType!, $hardwareAddress: String!) {
        gatewayInstallCandidate(type: $type, hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
        type,
      },
    })

    return !result.errors
  },

  async reinstallGateway (hardwareAddress) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($hardwareAddress: String!) {
        gatewayReinstallGateway(hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
      },
    })

    return !result.errors
  },

  async claimGateway (gateway) {
    return await apolloClient.mutate({
      mutation: gql`mutation ($hardwareAddress: String!, $serialNumber: String!) {
        gatewayClaimGateway(hardwareAddress: $hardwareAddress, serialNumber: $serialNumber)
      }`,
      variables: {
        ...gateway,
      },
    })
  },

  async updateGatewayFirmware (hardwareAddress) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($hardwareAddress: String!) {
        gatewayUpdateFirmware(hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
      },
    })

    return !result.errors
  },

  async refreshGatewayIpAddress (hardwareAddress) {
    const result = await apolloClient.mutate({
      mutation: gql`mutation ($hardwareAddress: String!) {
        gatewayRefreshGatewayIpAddress(hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
      },
    })

    return !result.errors
  },

  updateGateway (gateway) {
    return apolloClient.mutate({
      mutation: gql`mutation($gateway: GatewayUpdateInput!) {
        gatewayUpdate(gateway: $gateway)
      }`,
      variables: {
        gateway: {
          name: gateway.name,
          description: gateway.description,
          hardwareAddress: gateway.hardwareAddress,
        },
      },
    })
  },

  async deleteGateway (hardwareAddress) {
    return apolloClient.mutate({
      mutation: gql`mutation ($hardwareAddress: String!) {
        gatewayDeleteGateway(hardwareAddress: $hardwareAddress)
      }`,
      variables: {
        hardwareAddress,
      },
    })
  },
}
