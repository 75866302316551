export default {
  UNKNOWN: 'UNKNOWN',
  NOT_INSTALLED: 'NOT_INSTALLED',
  INSTALLING: 'INSTALLING',
  INSTALL_FAILED: 'INSTALL_FAILED',
  REINSTALL_FAILED: 'REINSTALL_FAILED',
  INSTALL_FAILED_RECOVERABLE: 'INSTALL_FAILED_RECOVERABLE',
  REINSTALL_FAILED_RECOVERABLE: 'REINSTALL_FAILED_RECOVERABLE',
  INSTALL_FAILED_UNAUTHENTICATED: 'INSTALL_FAILED_UNAUTHENTICATED',
  INSTALLED: 'INSTALLED',
}
