import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { AppStatus } from '~gro-plugins'
import { computed } from 'vue'

export default {
  getSmartboxes (pollInterval = 10 * 1000, paused = false) {
    const { result, loading, refetch } = useQuery(gql`
      query {
        smartbox {
          all {
            _id: hardwareAddress
            name
            hardwareAddress
            serialNumber
            ipAddress
          }
        }
      }`, {}, {
      pollInterval,
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
    })

    return {
      loading,
      refetch,
      smartboxes: useResult(result, [], data => data.smartbox.all),
    }
  },
  getSmartbox (hardwareAddress) {
    const { result, loading, onResult, refetch } = useQuery(gql`
      query($hardwareAddress: ID!) {
        smartbox {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            name
            hardwareAddress
            serialNumber
            ipAddress
          }
        }
      }`, {
      hardwareAddress,
    })

    return {
      loading,
      onResult,
      refetch,
      smartbox: useResult(result, [], data => data.smartbox.get),
    }
  },
}
