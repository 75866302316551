// import BatteryPercentageValue from '../components/BatteryPercentageValue'

export const id = 'BATTERY_PERCENTAGE'
export default {
  // valueComponent: BatteryPercentageValue,
  icon: 'battery-full',
  formatValue (rawValue) {
    return Math.round(rawValue)
  },
}
