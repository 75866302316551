export default {
  colors: {
    primary: '#FF0000',
    secondary: '#FF0000',
  },
  formatValue (rawValue) {
    return rawValue
  },
  icon: 'help',
}
