import moment from 'moment'
import _ from 'lodash'
import { computed, watchEffect, onBeforeUnmount, watch, ref } from 'vue'
import { SensorTypeHelper } from '~gro-modules/Sensor'
import deepmerge from 'deepmerge'
import { AvailableTypesHelper } from '~gro-modules/DeviceGroup'
import { id as TEMP } from '~gro-modules/Sensor/types/TEMP'
import { id as ROOT_TEMP } from '~gro-modules/Sensor/types/ROOT_TEMP'
import { asset } from '~gro-plugins/assetMixin'

const REASONABLE_MINUTES_THRESHOLD = 10

export default class {
  constructor (chartType) {
    this.chartType = chartType
    this.yAxis = ref([])
    this.series = ref([])
    this.emit = null
    this.timer = null
    this.settings = null
    this.timezone = null
    this.componentProperties = {}
    this.chart = null
    this.newChartLoading = ref(true)
    this.dataLoading = ref(true)

    this.setSeriesRangeDebounced = _.debounce(this.setSeriesRange.bind(this), 500)
  }

  setup (props, emit, chartRef, componentProperties) {
    let oldSettings = { ...props.settings }
    this.singleSource = !!componentProperties.singleSource
    this.settings = props.settings
    this.timezone = props.timezone
    this.chart = chartRef
    this.componentProperties = componentProperties
    this.emit = emit

    onBeforeUnmount(this.resetTimer.bind(this))
    const vue = this
    const chartOptions = computed(() => {
      const noData = !this.newChartLoading.value && this.series.value.length === 0
      const xAxis = this.calculatePlotBands(props.settings.group?.lightsOn, props.settings.group?.lightsOff)
      if (noData) xAxis.crosshair = false

      const options = componentProperties.chartOptions({
        chart: {
          height: props.height,
        },
        time: {
          getTimezoneOffset: function (timestamp) {
            return -moment.tz(timestamp, props.timezone).utcOffset()
          },
        },
        tooltip: {
          hideDelay: 10,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          style: {
            padding: 0,
          },
          split: true,
          formatter: function () {
            const ITEM_HEIGHT = 18
            const currentChartHeight = this.points[0].series.chart.chartHeight
            const tooltipEntriesTotalCount = this.points[0].series.chart.series.length
            const NUMBER_OF_COLUMNS = Math.ceil(
              (tooltipEntriesTotalCount * ITEM_HEIGHT) / (currentChartHeight - 130),
            )
            // const INDEX = this.points[0].point.index // TODO group by sensor type and show no data as dash
            // this.points[0].point.series.chart.series.forEach(serie => {
            //   if (serie.data[INDEX]) {
            //     console.log(serie.data[INDEX].series.userOptions.sensorType, serie.data[INDEX].rawY)
            //   } else {
            //     console.log(serie.data[INDEX].series.userOptions.sensorType, '-')
            //   }
            // })
            const mapped = this.points.map(function (point) {
              const type = point.series.userOptions.sensorType
              const style = point.series.options.opacity === 1 ? 'font-weight: 700;' : 'font-weight: 400;'
              return `<div class="line-tooltip ${type}" style="${style} height: ${ITEM_HEIGHT}px;">
              ${point.point.rawY} ${SensorTypeHelper.$t(type, 'unit')}
              </div>`
            }).join('')
            const mainTooltip = `
            <div class="tooltip-data" style="columns:${NUMBER_OF_COLUMNS} ;"> ${mapped} </div>
            `
            const isImperialSystem = 0 // TODO hook into user metric settings?
            const tooltipDate = moment.tz((this.x), props.timezone)
              .format(isImperialSystem ? 'hh:mm A - MMM DD' : 'HH:mm - MMM DD')
            const timeStamp = `
            <span class="tooltip-date">` + tooltipDate + `
            </span>
            `
            return [timeStamp, mainTooltip]
          },
        },
        xAxis,
      })
      if (componentProperties.navigatorWindow) {
        options.xAxis.events = {
          afterSetExtremes ({ trigger, max }) {
            if (trigger === 'navigator') {
              vue.setSeriesRangeDebounced(moment.tz(max, this.timezone).utc())
            }
          },
        }
        options.navigator.enabled = true
        options.navigator.xAxis.min = moment.utc().tz(this.timezone).subtract(30, 'days').valueOf()
        options.navigator.xAxis.max = moment.utc().tz(this.timezone).valueOf()
      }

      if (noData && this.newChartLoading.value) {
        options.navigator = { enabled: false }
      }
      return options
    })

    watchEffect(() => {
      if (componentProperties.navigatorWindow?.value?.length) {
        this.enableNavigatorData()
      }
    })

    watch(this.newChartLoading, async loading => {
      this.emit('update:loading', !!loading)
    })

    watch(componentProperties.windows, async windows => {
      if (this.newChartLoading.value) this.initiateAxis()
      const availableTypes = await this.updateSeries(windows)
      if (availableTypes === undefined) return
      if (this.newChartLoading.value) {
        this.settings.sensorTypes.forEach(type => {
          type.available = availableTypes.hasOwnProperty(type.name)
        })
        const identifier = this.settings.group
          ? this.settings.group.uuid
          : this.settings.comparedDevices?.length > 0
            ? this.settings.comparedDevices[0].serialNumber
            : null
        AvailableTypesHelper.store(identifier, availableTypes)
      }

      this.newChartLoading.value = componentProperties.deviceGroupSeriesQuery?.loading?.value
      this.resetXExtremes()
    }, { immediate: true })

    watchEffect(() => {
      this.dataLoading.value =
        componentProperties.deviceGroupSeriesQuery?.loading?.value ||
        componentProperties.deviceSeriesQuery?.loading?.value
    })

    watch(props.settings, (settings) => {
      const groupChanged = settings.group && settings.group.uuid !== oldSettings.group?.uuid
      if (groupChanged) {
        this.setSeriesRange(moment.utc(), true)
        this.resetXExtremes()
        this.newChartLoading.value = true
      } else if (settings.comparedGroups) {
        const comparedGroupsChanged = settings.comparedGroups.length > oldSettings.comparedGroups.length ||
          settings.comparedGroups.find(group => !oldSettings.comparedGroups.find(oldGroup => oldGroup.uuid === group.uuid))
        if (comparedGroupsChanged) {
          this.newChartLoading.value = true
        }
      } else if (settings.comparedDevices) {
        const comparedDevicesChanged = settings.comparedDevices.length > oldSettings.comparedDevices.length ||
          settings.comparedDevices.find(device => !oldSettings.comparedDevices.find(oldDevice => oldDevice.serialNumber === device.serialNumber))
        if (comparedDevicesChanged) {
          this.newChartLoading.value = true
        }
      }

      if (settings.range.interval !== oldSettings.range?.interval) {
        this.resetTimer()
        this.setSeriesRange(null, true)
        this.resetXExtremes()
      }

      settings.sensorTypes.forEach((type) => {
        if (!this.chart?.value) return
        let updateAxis = true
        if (type.enabled === false && [TEMP, ROOT_TEMP].includes(type.name)) {
          const otherTemp = type.name === TEMP ? ROOT_TEMP : TEMP
          const otherSetting = settings.sensorTypes.find(type => type.name === otherTemp)
          if (otherSetting && otherSetting.enabled) {
            updateAxis = false
          }
        }
        this.chart.value.updateVisibility(type, type.enabled, updateAxis)
      })
      oldSettings = { ...settings }
    })

    watch(props.height, () => {
      if (!this.chart?.value) return
      this.chart.value.reflow()
    })

    watchEffect(() => {
      if (!componentProperties.seriesRange.to) return
      const isLive = moment.utc().diff(componentProperties.seriesRange.to, 'minutes') < REASONABLE_MINUTES_THRESHOLD
      const interval = this.settings.range?.liveModeInterval
      if (interval && isLive && !this.timer) {
        this.startTimer(interval)
      } else if (!isLive && this.timer) {
        this.resetTimer()
      }
    })

    return {
      ...componentProperties,
      yAxis: this.yAxis,
      series: this.series,
      chartOptions,
      newChartLoading: this.newChartLoading,
      dataLoading: this.dataLoading,
      refreshAxisLimits: this.refreshAxisLimits.bind(this),
    }
  }

  startTimer (interval) {
    if (this.timer) return
    this.timer = setInterval(() => {
      if (this.newChartLoading.value) {
        return this.slowDownTimer(interval)
      }
      this.componentProperties.seriesRange.to = this.componentProperties.seriesRange.to.clone().add(interval, 'ms')
      this.componentProperties.seriesRange.from = this.componentProperties.seriesRange.from.clone().add(interval, 'ms')
    }, interval)
  }

  async slowDownTimer (oldInterval) {
    this.resetTimer()
    let newInterval
    for (newInterval = oldInterval + 1000; newInterval < 60000; newInterval += 5000) {
      await new Promise(resolve => setTimeout(resolve, 5000))
      if (!this.newChartLoading.value) {
        this.startTimer(newInterval)
        return
      }
    }
  }

  resetTimer () {
    if (!this.timer) return
    clearInterval(this.timer)
    this.timer = null
  }

  async initiateAxis () {
    this.yAxis.value = await Promise.all(this.settings.sensorTypes.map(async (type) => {
      const typeSettings = await SensorTypeHelper.getSettingAsync(type.name, 'axis', this.chartType)
      return deepmerge(typeSettings, {
        sensorType: type.name,
        showEmpty: true,
      })
    }))
  }

  async refreshAxisLimits (sensorTypes = []) {
    const force = sensorTypes.length === 0
    const newAxis = await Promise.all(this.yAxis.value.filter(axis => {
      return force || sensorTypes.includes(axis.sensorType)
    }).map(async axis => {
      const typeSettings = await SensorTypeHelper.getSettingAsync(axis.sensorType, 'axis', this.chartType)
      return deepmerge(axis, typeSettings)
    }))
    this.chart.value.updateAxis(newAxis, false)
  }

  async updateSeries (windows) {
    const identifier = this.settings.group?.visible
      ? this.settings.group.uuid
      : this.settings.comparedDevices?.length > 0
        ? this.settings.comparedDevices[0].serialNumber
        : null
    const newSeries = []
    const availableTypes = AvailableTypesHelper.load(identifier)
    if (!windows.length) return
    await Promise.all(windows.map(async (windowData) => {
      if (!windowData) return
      await Promise.all(windowData.map(async seriesData => {
        const yAxis = await SensorTypeHelper.getSettingAsync(seriesData.type, 'axis', this.chartType)
        const colorLevel = seriesData.deviceGroup && !this.singleSource ? 'secondary' : 'primary'
        const color = await SensorTypeHelper.getColorAsync(seriesData.type, colorLevel)
        const values = await SensorTypeHelper.getValuesAsync(seriesData.type, seriesData.values)
        const formattedValues = await SensorTypeHelper.formatValuesAsync(seriesData.type, seriesData.values)
        const type = this.settings.sensorTypes.find(type => type.name === seriesData.type)

        let visible = false
        if (type) {
          availableTypes[type.name] = values[values.length - 1]
          type.available = true
          visible = type.enabled
        }

        newSeries.push({
          name: `${seriesData.type} -${seriesData.deviceGroup?.uuid || seriesData.device?.serialNumber}`,
          sensorType: seriesData.type,
          deviceGroup: seriesData.deviceGroup,
          device: seriesData.device,
          yAxis: yAxis.id,
          dashStyle: seriesData.deviceGroup && !this.singleSource ? 'ShortDot' : 'Solid',
          animation: false,
          showInNavigator: false,
          visible,
          lineColor: color,
          marker: {
            fillColor: color,
          },
          turboThreshold: 0,
          data: !values
            ? []
            : seriesData.values.map((value, index) => {
              return {
                x: seriesData.timestamps[index] * 1000,
                y: values[index],
                rawY: formattedValues[index],
              }
            }),
        })
      }))
    }))

    this.chart.value.updateAxis(this.yAxis.value.map(axis => {
      axis.visible = !!availableTypes[axis.sensorType]
      return axis
    }))

    this.series.value = newSeries
    return availableTypes
  }

  resetXExtremes () {
    const seriesRange = this.componentProperties.seriesRange
    this.chart.value.setExtremes(seriesRange.from.valueOf(), seriesRange.to.valueOf())
  }

  setSeriesRange (to, force = false) {
    if (!to) {
      if (force || !this.componentProperties.seriesRange.from) {
        to = moment.utc()
      } else {
        to = this.componentProperties.seriesRange.from.clone().add(this.settings.range.interval, this.settings.range.unit)
      }
    }
    if (!moment().isSameOrAfter(to)) {
      to = moment.utc()
    }
    if (
      force ||
      !this.componentProperties.seriesRange.to ||
      Math.abs(this.componentProperties.seriesRange.to.diff(to, 'minutes')) > REASONABLE_MINUTES_THRESHOLD
    ) {
      this.componentProperties.seriesRange.to = to
      this.componentProperties.seriesRange.from = to.clone().subtract(this.settings.range.interval, this.settings.range.unit)
    }
  }

  async enableNavigatorData () {
    const windows = this.componentProperties.navigatorWindow.value[0]
    if (!windows.length) return
    const window = windows[0]
    if (!window.values.length) return
    const values = await SensorTypeHelper.getValuesAsync(window.type, window.values)

    this.chart.value.updateOptions({
      navigator: {
        series: [{
          id: 'navigator',
          name: `Navigator ${SensorTypeHelper.$t(window.type, 'label')}`,
          sensorType: window.type,
          showInNavigator: true,
          animation: false,
          lineColor: await SensorTypeHelper.getColor(window.type),
          data: window.values.map((value, index) => {
            return {
              x: window.timestamps[index] * 1000,
              y: values[index],
              rawY: value,
            }
          }),
        }],
      },
    })
  }

  calculatePlotBands (on, off) {
    if (!on || !off) return {}
    const lightsOn = moment.utc(on, 'HH:mm')
    const lightsOff = moment.utc(off, 'HH:mm')
    const fromDate = moment.utc().subtract(31, 'days').tz(this.timezone)
    const toDate = moment.utc().tz(this.timezone)
    const plotBands = []
    const plotLines = []

    while (fromDate.isSameOrBefore(toDate)) {
      const from = fromDate.set({ hours: lightsOff.hours(), minutes: lightsOff.minutes() }).unix() * 1000
      const to = fromDate.set({
        hours: lightsOn.hours(),
        minutes: lightsOn.minutes(),
      }).clone().add(1, 'days').unix() * 1000

      plotLines.push({
        value: from,
        label: {
          text: '<img src="' + asset('/images/icons/LIGHTS-OFF_12x12.svg') + '" style="width:12px;">',
          useHTML: true,
          style: {
            width: '20px',
            transform: 'translate(-10px, -20px)',
          },
        },
      })
      plotLines.push({
        value: to,
        label: {
          text: '<img src="' + asset('/images/icons/LIGHTS-ON_12x12.svg') + '" style="width:12px;">',
          useHTML: true,
          style: {
            width: '20px',
            transform: 'translate(-10px, -20px)',
          },
        },
      })

      plotBands.push({
        color: '#f6f8fc',
        from,
        to,
      })
      fromDate.add(1, 'days')
    }
    return { plotBands, plotLines }
  }
}
