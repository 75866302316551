import { apolloClient } from '~gro-plugins'
import gql from 'graphql-tag'

export default {
  async addSmartbox (smartbox) {
    return apolloClient.mutate({
      mutation: gql`mutation($smartbox: SmartboxAddInput!) {
        smartboxAddSmartbox(smartbox:$smartbox)
      }`,
      variables: {
        smartbox,
      },
    })
  },
  async updateSmartbox (smartbox) {
    return apolloClient.mutate({
      mutation: gql`mutation($smartbox: SmartboxUpdateInput!) {
        smartboxUpdateSmartbox(smartbox:$smartbox)
      }`,
      variables: {
        smartbox,
      },
    })
  },
}
