import { ROOT_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'WC'

export default {
  axis: async (chartType) => {
    const setting = await Settings.get('axis.WC', { autoScale: true })
    return {
      id,
      opposite: false,
      min: setting.autoScale ? null : setting.min,
      max: setting.autoScale ? null : setting.max,
      margin: 10,
      ceiling: 100,
      floor: 0,
      allowDecimals: false,
      tickAmount: setting.autoScale ? 5 : 2,
      alignTicks: setting.autoScale,
      gridLineWidth: 0,
      title: {
        enabled: false,
      },
      labels: chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              fontSize: '10px',
              color: '#56b3ff',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: {
    min: 0,
    max: 100,
    stepSize: 1,
  },
  colors: {
    primary: '#56b3ff',
    secondary: '#56b3ff',
  },
  icon: 'water',
  svg: asset('/images/icons/WC_Color_12x12.svg'),
  zone: ROOT_ZONE,
  zoneListPosition: 0,
  order: 1,
}
