import { CLIMATE_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'RH'
export default {
  axis: async (chartType) => {
    const setting = await Settings.get('axis.RH', { autoScale: true })
    return {
      id,
      opposite: true,
      softMax: setting.autoScale ? 90 : null,
      softMin: setting.autoScale ? 40 : null,
      min: setting.autoScale ? null : setting.min,
      max: setting.autoScale ? null : setting.max,
      floor: setting.autoScale ? 0 : null,
      ceiling: setting.autoScale ? 100 : null,
      minRange: 20,
      alignTicks: true,
      endOnTick: true,
      gridLineWidth: 0,
      tickAmount: 5,
      title: {
        enabled: false,
      },
      labels: chartType === 'conditions' && chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              fontSize: '10px',
              color: '#cd6be5',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: {
    min: 0,
    max: 100,
    stepSize: 1,
  },
  colors: {
    primary: '#cd6be5',
    secondary: '#cd6be5',
  },
  formatValue (rawValue) {
    const rounded = Math.round(rawValue * 100) / 100
    return rounded.toFixed(2)
  },
  icon: 'water',
  svg: asset('/images/icons/HUMIDITY_Color_12x12.svg'),
  zone: CLIMATE_ZONE,
  zoneListPosition: 1,
  order: 5,
}
