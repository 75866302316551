import deepmerge from 'deepmerge'
import { Translator } from '~gro-plugins'

export default function () {
  return deepmerge.all([{
    title: {
      text: '',
      align: 'left',
      floating: true,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    labels: {
      style: {
        align: 'center',
        fontSize: '10px',
      },
    },
    rangeSelector: {
      enabled: false,
    },
    lang: {
      noData: Translator.translate('common.No data'),
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
    chart: {
      animation: false,
      plotBorderColor: '#e3e8ef',
      plotBorderWidth: 1,
      marginTop: 34,
      height: null,
      backgroundColor: 'transparent',
      fontFamily: 'Proxima Nova',
      panning: false,
      type: 'line',
      pinchType: 'none',
      resetZoomButton: false,
      zoomType: 'none',
    },
    yAxis: {
      gridLineColor: '#e3e8ef',
    },
    xAxis: {
      crosshair: {
        snap: false,
        width: 2,
        color: '#9999',
        dashStyle: 'solid',
      },
      dateTimeLabelFormats: {
        millisecond: '%h:%M',
        second: '%H:%M',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e %b',
        week: '%e %b',
      },
      gridLineColor: '#e3e8ef',
      gridLineWidth: 0,
      minorTickWidth: 0,
      labels:
        {
          style: {
            color: '#262c34',
          },
        },
      lineColor: '#f4f5f6',
      tickWidth: 0,
      type: 'datetime',
    },
    plotOptions: {
      series: {
        softThreshold: true,
      },
      spline: {
        states: {
          hover: {
            halo: null,
          },
          inactive: {
            enabled: false,
          },
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
        },
        lineWidth: 2,
      },
    },
    tooltip: {
      animation: false,
      enabled: true,
      shared: true,
      hideDelay: 0,
      backgroundColor: 'var(--ion-color-primary)',
      xDateFormat: '%H:%M - %b:%e',
      shadow: false,
      outside: true,
      borderWidth: 0,
      borderRadius: 6,
      padding: 4,
      style: {
        color: '#FFF',
      },
    },
  }, ...arguments])
}
