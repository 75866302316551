import { useQuery, useResult } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import get from 'get-value'
import installStatus from './installStatus'
import { apolloClient, AppStatus } from '~gro-plugins'
import { scannerStatus } from '~gro-modules/Arp'
import { computed } from 'vue'

export default {
  getInstalled (pollInterval = 10 * 1000, fetchPolicy = 'cache-and-network', paused = false) {
    const { result, loading, refetch } = useQuery(gql`
      query getInstalledGateways{
        gateway {
          installed {
            _id: hardwareAddress
            name
            hardwareAddress
            ipAddress
            createdAt
            type
            lastSeen
          }
        }
      }`, {}, {
      pollInterval,
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
      fetchPolicy,
    })

    return {
      loading,
      refetch,
      gateways: useResult(result, [], data => data.gateway.installed),
    }
  },
  getGateways (pollInterval = 10 * 1000, fetchPolicy = 'cache-and-network', paused = false) {
    const { result, loading, refetch } = useQuery(gql`
      query getGateways{
        gateway {
          all {
            _id: hardwareAddress
            name
            hardwareAddress
            installStatus
            ipAddress
            createdAt
            type
            lastSeen
          }
        }
      }`, {}, {
      pollInterval,
      enabled: computed(() => AppStatus.inForeground.value && (!paused || !paused.value)),
      fetchPolicy,
    })

    return {
      loading,
      refetch,
      gateways: useResult(result, [], data => data.gateway.all),
    }
  },
  getGateway (hardwareAddress, pollInterval = null) {
    const { result, loading, onResult, refetch } = useQuery(gql`
      query getGateway($hardwareAddress: String!) {
        gateway {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            name
            description
            hardwareAddress
            ipAddress
            type
            installedAt
            installStatus
            createdAt
            firmwareVersion
            lastSeen
            model
            mode
            ipAddressReachable
          }
        }
      }`, {
      hardwareAddress,
    }, {
      pollInterval,
      enabled: AppStatus.inForeground,
      context: {
        dontDisconnect: true,
      },
    })

    return {
      loading,
      onResult,
      refetch,
      gateway: useResult(result, [], data => data.gateway.get),
    }
  },
  getGatewayIsConnected (hardwareAddress, pollInterval = null) {
    const { result, error } = useQuery(gql`
      query getGateway($hardwareAddress: String!) {
        gateway {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            isConnected
          }
        }
      }`, {
      hardwareAddress,
    }, {
      pollInterval,
      enabled: AppStatus.inForeground,
      context: {
        dontDisconnect: true,
      },
    })

    return {
      isConnected: computed(() => {
        if (error.value) {
          return null
        }
        return !!result.value?.gateway?.get?.isConnected
      }),
    }
  },
  async getScanStatus (hardwareAddress = null) {
    const result = await apolloClient.query({
      query: gql`query getGatewayScanStatus($hardwareAddress: String) {
        gateway {
          scanStatus(hardwareAddress: $hardwareAddress) {
            status
            results {
              hardwareAddress
              ipAddress
            }
          }
        }
      }`,
      variables: { hardwareAddress },
      fetchPolicy: 'no-cache',
    })
    return {
      status: get(result, 'data.gateway.scanStatus.status', { default: scannerStatus.IDLE }),
      candidates: get(result, 'data.gateway.scanStatus.results', { default: [] }),
    }
  },
  getInstallStatus (hardwareAddress, pollInterval = null) {
    const { result, loading } = useQuery(gql`
      query getGatewayInstallStatus($hardwareAddress: String!) {
        gateway {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            installStatus
          }
        }
      }`, {
      hardwareAddress,
    }, { pollInterval, enabled: AppStatus.inForeground, fetchPolicy: 'no-cache' })

    return {
      loading,
      installStatus: useResult(result, installStatus.INSTALLING, data => data.gateway.get.installStatus),
    }
  },
  async getInstallStatusAsync (hardwareAddress) {
    const result = await apolloClient.query({
      query: gql` query getGatewayInstallStatusAsync($hardwareAddress: String!) {
        gateway {
          get(hardwareAddress: $hardwareAddress) {
            _id: hardwareAddress
            installStatus
          }
        }
      }`,
      fetchPolicy: 'no-cache',
      variables: { hardwareAddress },
    })

    return get(result, 'data.gateway.get.installStatus', { default: installStatus.UNKNOWN })
  },
}
