import { CLIMATE_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'VPD'

export default {
  axis: async (chartType) => {
    const setting = await Settings.get('axis.VPD', { autoScale: true })
    return {
      id,
      opposite: true,
      softMax: setting.autoScale ? 2 : null,
      min: setting.autoScale ? 0 : setting.min,
      max: setting.autoScale ? 5 : setting.max,
      tickAmount: 5,
      allowDecimals: false,
      title: {
        enabled: false,
      },
      labels: chartType === 'conditions' && chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              fontSize: '10px',
              color: '#bd9b75',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: {
    min: 0,
    max: 10,
    stepSize: 0.5,
    conversion: 1000,
  },
  convertValues (rawValues) {
    return rawValues.map(value => this.convertValue(value))
  },
  convertValue: (rawValue) => {
    if (typeof rawValue !== 'number') {
      return null
    }
    return rawValue / 1000 // from Pa to kPa
  },
  colors: {
    primary: '#bd9b75',
    secondary: '#bd9b75',
  },
  icon: 'analytics',
  svg: asset('/images/icons/VPD_Color_12x12.svg'),
  zone: CLIMATE_ZONE,
  zoneListPosition: 3,
  order: 7,
}
