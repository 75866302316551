import { CLIMATE_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'CO2'

export default {
  axis: async (chartType) => {
    const setting = await Settings.get('axis.CO2', { autoScale: true })
    return {
      id,
      opposite: false,
      min: setting.autoScale ? null : setting.min,
      max: setting.autoScale ? null : setting.max,
      softMax: setting.autoScale ? 2000 : null,
      minRange: 100,
      ceiling: setting.autoScale ? 5000 : null,
      tickAmount: 5,
      allowDecimals: false,
      title: {
        enabled: false,
      },
      labels: chartType === 'conditions' && chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              color: '#00dfd0',
              fontSize: '10px',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: {
    min: 0,
    max: 5000,
    stepSize: 100,
  },
  colors: {
    primary: '#00dcaf',
    secondary: '#aff7e5',
  },
  icon: 'cloud',
  svg: asset('/images/icons/CO2_Color_12x12.svg'),
  zone: CLIMATE_ZONE,
  zoneListPosition: 2,
  order: 6,
}
