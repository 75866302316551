import axios from 'axios'

export default {
  async prepareDownload (slug) {
    const response = await axios.get(`${process.env.VUE_APP_CLOUD_VIEWER_URL || ''}/api/graph/prepareDownload/${slug}`)
    return response.data
  },
  downloadExcel (slug) {
    const a = document.createElement('a')
    a.href = `${process.env.VUE_APP_CLOUD_VIEWER_URL || ''}/api/graph/excelDownload/${slug}`
    a.download = true
    a.click()
  },
  async downloadFile (url) {
    const response = await axios.get(url)
    try {
      const rows = response.data.split('\n')
      let header
      let graphMeta = null
      if (/^-?\d+$/.test(rows[2].split(',')[0])) {
        header = rows.shift().split(',')
      } else {
        graphMeta = {}
        const infoHeader = rows.shift().split(',')
        const infoValues = rows.shift().split(',')
        infoHeader.forEach((key, index) => {
          graphMeta[key] = infoValues[index] || null
        })
        header = rows.shift().split(',')
      }
      return {
        graphMeta,
        dataRows: rows.map(row => {
          const object = {}
          const values = row.split(',')
          header.forEach((key, index) => {
            object[key] = values[index] || null
          })
          return object
        }),
      }
    } catch (e) {
      throw Error('Invalid datafile')
    }
  },

  async prepareUpload (graphType = '') {
    const response = await axios.post(`${process.env.VUE_APP_CLOUD_VIEWER_URL || ''}/api/graph/prepareUpload`, {
      region: this.$region,
      graphType,
    })
    return response.data
  },

  async uploadFile (uploadInfo, file) {
    return axios.put(uploadInfo.uploadUrl, new Blob([file]), {
      headers: {
        'Content-Type': 'text/csv',
      },
    })
  },
}
