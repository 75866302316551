// import SignalStrengthValue from '../components/SignalStrengthValue'

export const id = 'SIGNAL_STRENGTH'
export default {
  formatValue (rawValue) {
    return Math.round(rawValue)
  },
  icon: 'radio',
  // valueComponent: SignalStrengthValue
}
