import { ROOT_ZONE } from '../zones'
import { Settings } from '~gro-modules/Auth'
import { asset } from '~gro-plugins/assetMixin'

export const id = 'EC'
export default {
  axis: async (chartType) => {
    const setting = await Settings.get('axis.EC', { autoScale: true })
    return {
      id,
      opposite: true,
      min: setting.autoScale ? null : setting.min,
      max: setting.autoScale ? null : setting.max,
      argin: 10,
      ceiling: 20, // applies to autoscale only
      floor: 0, // applies to autoscale only
      minPadding: 0,
      allowDecimals: true,
      minRange: 0.1,
      tickAmount: setting.autoScale ? 5 : 2,
      alignTicks: setting.autoScale,
      // gridLineWidth: setting.autoScale ? 1 : 0,
      title: {
        enabled: false,
      },
      labels: chartType !== 'measurements'
        ? {
            enabled: true,
            style: {
              fontSize: '10px',
              color: '#ff009e',
            },
          }
        : {
            enabled: false,
          },
    }
  },
  axisLimits: {
    min: 0,
    max: 20,
    stepSize: 0.5,
  },
  colors: {
    primary: '#ff009e',
    secondary: '#ff009e',
  },
  formatValue (rawValue) {
    const rounded = Math.round(rawValue * 100) / 100
    return rounded.toFixed(2)
  },
  icon: 'flash',
  svg: asset('/images/icons/EC_Color_12x12.svg'),
  zone: ROOT_ZONE,
  zoneListPosition: 1,
  order: 2,
}
